<template lang="pug">
    Layout
        div()
</template>

<script>
    import Layout from '../layouts/main'
    import constructModalSettings from 'mixins/modalSettings'
    import formValidator from 'mixins/formValidator'
    import modalSettings from 'mixins/modalSettings'
    import constants from 'helpers/constants'

    export default {
        name: "Interview",

        components: {
            Layout,
        },

        mixins: [
            constructModalSettings,
            formValidator,
            modalSettings,
        ],

        mounted () {
            this.$store.dispatch("showModal", this.constructModalSettings(
                constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_INITIATE_CAPTURE,
                false,
                {},
                false,
                true)
            )
        },
    }
</script>

<style lang="scss" scoped>
</style>
